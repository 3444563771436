<template>
	<div id="coverupload">
		<el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="top">
			<el-form-item label="会议背景图(主KV)" prop="bgImage">
				<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
					:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" :file-list="fileList1">
					<el-image :src="registerForm['1'].file?registerForm['1'].file:registerForm['1'].url"
						v-if="registerForm['1']" fit="cover" class="avatar"></el-image>
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>

			<el-form-item label="会议海报" prop="bill">
				<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
					:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload" :file-list="fileList2">
					<img :src="registerForm['2'].file?registerForm['2'].file:registerForm['2'].url"
						v-if="registerForm['2']" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>

			<el-form-item>
				<div class="options">
					<el-button class="bank" @click="bank()">返回</el-button>
					<el-button type="primary" class="next" @click="onSubmit('registerForm')">下一步</el-button>
				</div>
			</el-form-item>
		</el-form>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import {
		updateMeetingImage,
		getMeetingById
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'coverupload',
		data() {
			return {
				meetingId: '',
				projectId: '',
				baseUrl: '',
				fileList1: [],
				fileList2: [],
				dialogImageUrl: '',
				dialogVisible: false,
				registerForm: {
					1: '',
					2: ''
				},
				rules: {
					bgImage: [{
						validator: (rule, value, callback) => {
							if (this.registerForm[1]) {
								callback();
							} else {
								callback(new Error('请上传会议背景图(KV)'));
							}
						},
						trigger: 'blur'
					}],
					bill: [{
						validator: (rule, value, callback) => {
							if (this.registerForm[2]) {
								callback();
							} else {
								callback(new Error('请上传会议海报(会议宣传图)'));
							}
						},
						trigger: 'blur'
					}]
				},
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.meetingId = this.$route.query.mId;
			this.projectId = this.$route.query.pId;

			this.getMeetingDetail();
		},
		mounted() {},
		methods: {
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							bgImage: this.registerForm[1].path,
							bill: this.registerForm[2].path,
							id: this.meetingId
						}

						updateMeetingImage(data).then(res => {
							if (res.code == 200) {
								this.$router.push({
									name: 'confmeet',
									query: {
										mId: this.meetingId
									}
								})
								this.$message({
									message: '更新成功',
									type: 'success'
								});
								this.$notify({
									type: 'success',
									title: '更新成功',
									message: 'KV/海报更新成功',
									position: 'bottom-right'
								});
							}
						})
					}
				});
			},

			getMeetingDetail() {
				let data = {
					meetingId: this.meetingId
				}
				getMeetingById(data).then(res => {
					if (res.code == 200) {
						console.log(res.data)
						if (res.data.bgImage) this.registerForm['1'] = {
							file: res.data.bgImage,
							url: res.data.bgImage,
							path: res.data.bgImage
						};
						if (res.data.bill) this.registerForm['2'] = {
							file: res.data.bill,
							url: res.data.bill,
							path: res.data.bill
						}
					}
				})
			},

			confirmFun() {
				this.$confirm('会议创建完成 是否现在通知全部参会人员?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '通知'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '不通知'
					});
				});
			},

			handleRemove(file) {
				console.log(this);
			},

			handleAvatarSuccess1(res, file) {
				// console.log(res);
				// console.log(file);
				this.registerForm['1'] = {
					file: URL.createObjectURL(file.raw),
					path: res
				}
			},

			handleAvatarSuccess2(res, file) {
				// console.log(res);
				// console.log(file);
				this.registerForm['2'] = {
					file: URL.createObjectURL(file.raw),
					path: res
				}
			},

			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
				}
				return isLt20M;
			},

			bank() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#coverupload {
		padding: 40px;

		.title {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.el-form {
			display: flex;
			flex-direction: column;
		}

		.upload {}

		.el-upload {
			display: flex;
			align-items: center;
			justify-content: center;

			.el-icon-plus {
				height: 100%;
			}
		}

		.imageList {
			.el-image {
				height: 148px;
				width: 148px;
			}
		}

		.el-icon-plus {
			width: 600px;
			height: 200px;
			font-size: 50px;
			padding: 20px;
			border: 1px dashed #eee;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.avatar {
			width: 600px;
			height: auto;
		}

		.options {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.el-button {
				width: 300px;
				height: 50px;
				// background-color: #007AFF;
				// color: #fff;
			}

			.next {
				// background-color: #007AFF;
				// color: #fff;
			}

			.bank {
				// background-color: #fff;
				// color: #FF5733;
				// border: 1px solid #FF5733;
			}
		}
	}
</style>